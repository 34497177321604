import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { ListItem } from '@latitude/list';
import { Heading3 } from '@latitude/heading';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import Icon from '@latitude/svg-inline/images/external-link.svg';
import SvgInline from '@latitude/svg-inline';
import { BREAKPOINT, FONT_WEIGHT ,
  ALIGN,
  COLOR,
  FONT_SIZE,
  LINE_HEIGHT,
  MARGIN
} from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';

import { Tile, TileContainer, TileIcon, StyledList } from '../_ContactUsStyle';
import PageData from '../contact-us.json';

export default () => (
  <Box.Section id="essential-numbers" backgroundColor={COLOR.GREY6}>
    <StyledHeading color={COLOR.BLACK} align={ALIGN.CENTER}>
      Still need to get in touch?
    </StyledHeading>

    <StyledText align={ALIGN.CENTER}>
      We&apos;ve listed some information to help you get in touch
    </StyledText>

    <TileGroup>
      <TileLost />
      <TileHardship />
    </TileGroup>

    <TileGroup>
      <TileFraud />
      <TileComplaints />
    </TileGroup>
  </Box.Section>
  );

const StyledHeading = styled(Heading3)`
  margin: 40px 0 0;
  font-size: 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    font-size: 32px;
    margin: 48px 0 0;
  }
`;

const StyledText = styled(Text)`
  margin: 0 0 24px;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0 0 32px;
  }
`;

const TileGroup = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: flex;
    flex-direction: row;
  }
`;

const TileLost = () => (
  <div
    css={`
      & > div {
        margin-left: auto;
        margin-right: auto;
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        display: flex;
        flex-basis: 65%;
        & > div {
          margin-right: 30px;
        }
      }
    `}
  >
    <TileContainer>
      <Tile tileBackground="blue">
        <TileIcon>
          <SvgInline name="lost-card" />
        </TileIcon>
        <Heading3
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M8}
        >
          {ReactHtmlParser(PageData.essentialNumbers[0].title)}
        </Heading3>
        <Text
          fontSize={FONT_SIZE.SMALL}
          lineHeight={LINE_HEIGHT.TIGHT.NORMAL}
          align={ALIGN.CENTER}
        >
          {ReactHtmlParser(PageData.essentialNumbers[0].description)}
        </Text>
      </Tile>
      <Tile>
        <StyledList pointless>
          {PageData.essentialNumbers[0].numbers.map(item => (
            <ListItem>
              <Text fontWeight={FONT_WEIGHT.MEDIUM} css="white-space:nowrap;">
                {item.label}
              </Text>{' '}
              <Link
                className="no-wrap"
                target="_blank"
                href={`tel:${item.value}`}
              >
                {item.value}
              </Link>
            </ListItem>
          ))}
        </StyledList>
      </Tile>
    </TileContainer>
  </div>
);

const TileFraud = () => (
  <div
    css={`
      & > div {
        margin-left: auto;
        margin-right: auto;
      }
      @media (min-width: ${BREAKPOINT.LG}) {
        display: flex;
        flex-basis: 65%;
        & > div {
          margin-right: 30px;
        }
      }
    `}
  >
    <TileContainer>
      <Tile tileBackground="blue">
        <TileIcon>
          <SvgInline name="declined-card" />
        </TileIcon>
        <Heading3
          color={COLOR.BLACK}
          align={ALIGN.CENTER}
          marginBottom={MARGIN.M8}
        >
          {ReactHtmlParser(PageData.essentialNumbers[1].title)}
        </Heading3>
        <Text
          fontSize={FONT_SIZE.SMALL}
          lineHeight={LINE_HEIGHT.TIGHT.NORMAL}
          align={ALIGN.CENTER}
        >
          {ReactHtmlParser(PageData.essentialNumbers[1].description)}
        </Text>
      </Tile>
      <Tile>
        <StyledList pointless>
          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>Within New Zealand</Text>{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`tel:${PageData.essentialNumbers[1].withinNZ}`}
            >
              {PageData.essentialNumbers[1].withinNZ}
            </Link>
          </ListItem>

          <ListItem>
            <Text fontWeight={FONT_WEIGHT.MEDIUM}>Outside New Zealand</Text>{' '}
            <Link
              className="no-wrap"
              target="_blank"
              href={`tel:${PageData.essentialNumbers[1].outsideNZ}`}
            >
              {PageData.essentialNumbers[1].outsideNZ}
            </Link>
          </ListItem>
        </StyledList>
      </Tile>
    </TileContainer>
  </div>
);

const TileHardship = () => (
  <TileContainer
    half
    css={`
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      & > div {
        flex-basis: 100% !important;
      }
    `}
  >
    <Tile>
      <Heading3
        color={COLOR.BLACK}
        align={ALIGN.CENTER}
        marginBottom={MARGIN.M8}
      >
        {ReactHtmlParser(PageData.essentialNumbers[3].title)}
      </Heading3>
      <Text
        fontSize={FONT_SIZE.SMALL}
        align={ALIGN.CENTER}
        css="whitespace:nowrap;"
      >
        {ReactHtmlParser(PageData.essentialNumbers[3].description)}
      </Text>
      <Link
        href={PageData.essentialNumbers[3].href}
        button="tertiary"
        target="_self"
        css="display:inline-block; margin:22px auto 0;"
        disableDefaultEventTracking
      >
        {PageData.essentialNumbers[3].linkLabel} &nbsp;
        <Icon />
      </Link>
    </Tile>
  </TileContainer>
);

const TileComplaints = () => (
  <TileContainer
    half
    css={`
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      & > div {
        flex-basis: 100% !important;
      }
    `}
  >
    <Tile>
      <Heading3
        color={COLOR.BLACK}
        align={ALIGN.CENTER}
        marginBottom={MARGIN.M8}
      >
        {ReactHtmlParser(PageData.essentialNumbers[2].title)}
      </Heading3>
      <Text fontSize={FONT_SIZE.SMALL} align={ALIGN.CENTER}>
        {ReactHtmlParser(PageData.essentialNumbers[2].description)}
      </Text>
      <Link
        href={PageData.essentialNumbers[2].href}
        button="tertiary"
        target='_self'
        css="display:inline-block; margin:22px auto 0;"
        disableDefaultEventTracking
      >
        {PageData.essentialNumbers[2].linkLabel} &nbsp;
        <Icon />
      </Link>
    </Tile>
  </TileContainer>
);
